import BootstrapMenu from ''

export const updateActiveItem = (navigationId = 'headerNavbar') => {
    const nav = document.querySelector('#' + navigationId);

    if (!nav) {
        return;
    }

    const activeItems = nav.querySelectorAll('[class*=active]');
    activeItems.forEach((el) => el.classList.remove('active', 'active-children'));

    const path = window.location.pathname;
    const link = nav.querySelector('[href="' + path + '"]');

    if (!link) {
        return;
    }

    // 1st Level
    link.closest('.has-dropdown')?.classList.add('active-children');

    // 2nd Level
    link.closest('.dropdown-level-1')?.parentElement?.classList.add('active-children');

    // Current Link Item
    link.parentElement?.classList.remove('active-children');
    link.parentElement?.classList.add('active');
    link.classList.add('active');
};


import Autosize from './autosize';
import { lazyloadImages } from './images';
import { updateActiveItem as updateActiveNavItem } from './navigation';
import Baguettebox from './baguette-box';
import Swiper from './swiper';
import Teich from './teich';
import Accordion from './accordion';

/**
 * Will be called each time a new page has been loaded.
 */
export const onLoad = (container?: HTMLElement) => {
    Autosize(container);
    Baguettebox();
    Swiper();
    updateActiveNavItem();
    updateActiveNavItem('topNavbar');
    lazyloadImages();
    Accordion(container);
    Teich();
};

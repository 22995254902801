import $ from 'jquery';

export default () => {
    const hoveredItems = $('[class*="hovered"]');

    hoveredItems.each(function () {
        $(this).removeClass('.hovered');
    });

    $('ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });

    const mmMenu = $('#wlymmenu');
    if (mmMenu.length) {
        $('.mobilemenutoggler').on('click', () => {
            const navbarsBottom = mmMenu.find('.mm-navbars_bottom');
            const panels = mmMenu.find('.mm-panels');

            mmMenu.find('#mm-0 .mm-btn_next').on('click', function () {
                navbarsBottom.addClass('hide-animated');
                panels.addClass('submenu-opened');
            });

            mmMenu.find('.mm-btn_prev').on('click', function () {
                navbarsBottom.removeClass('hide-animated');
                panels.removeClass('submenu-opened');
            });
        });
    }
};

import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import BaguetteBox from 'baguettebox.js';
import { merge } from 'ts-deepmerge';

export default () => {
    const nodes = Array.from(document.querySelectorAll('[data-swiper]')) as HTMLElement[];

    if (!nodes.length) {
        return;
    }

    nodes.forEach((el) => {
        let init = false;
        const swiper = new Swiper(el as HTMLElement, merge(
                {
                    modules: [Navigation, Pagination, Scrollbar, Autoplay],
                    // Optional parameters
                    direction: 'horizontal',
                    loop: true,
                    speed: 800,
                    autoplay: {
                        delay: 3000
                    },
                    grabCursor: false,
                    pagination: {
                        el: el.querySelector('.swiper-pagination'),
                        clickable: true
                    },
                    navigation: {
                        nextEl: el.querySelector('.swiper-button-next'),
                        prevEl: el.querySelector('.swiper-button-prev'),
                        enabled: true
                    },
                    scrollbar: {
                        draggable: true
                    }
                },
                JSON.parse(el.dataset?.swiper || '{}')
            )
        );

        swiper.on('init', () => {
            BaguetteBox.run('.swiper-wrapper');
        });

        el.addEventListener('lazyloaded', () => {
            if (!init) {
                swiper.init();
                init = true;
            }

            swiper.updateAutoHeight();
        });
    });
};
